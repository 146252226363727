<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="paymentReceivedSubmitDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          {{ this.model.name.singular }}
        </v-toolbar>

        <div class="pa-4">Please confirm that Payment Received from HRDC</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalPaymentReceivedSubmit()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="paymentReceivedSubmitDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              Payment Received Details ({{ this.formData.refundTypeDesc }})
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="isTaxpodAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="paymentReceivedSubmitDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Payment Received From HRDC</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <div>
        <div v-if="formData.refundType == 2" class="pa-3">
          <v-row>
            <v-col cols="6">
              <v-app-bar flat outlined elevation="0">
                <span>
                  <span class="text-button font-weight-bold">70% Case</span>
                </span>
              </v-app-bar>
            </v-col>
            <v-col cols="6">
              <v-app-bar flat outlined elevation="0">
                <span>
                  <span class="text-button font-weight-bold">30% Case</span>
                </span>
              </v-app-bar>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="6">
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Claim ID -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="Claim ID"
                  v-model="formData.claimId"
                  :error-messages="errorField.claimId"
                  readonly
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.claimId)"
                      >mdi-content-copy
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>

              <!-- Event Date Range -->
              <v-row class="px-5 align-items-center" v-if="isRefundCases">
                <v-col cols="12" lg="5" sm="5" md="5" class="px-0">
                  <v-menu
                    ref="menuEventStartDate"
                    v-model="menuEventStartDate"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        v-model="formData.eventStartDate"
                        :error-messages="errorField.eventStartDate"
                        label="Event Start Date (YYYY-MM-DD)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eventStartDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menuEventStartDate = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuEventStartDate.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                to
                <v-col cols="12" lg="5" sm="5" md="5">
                  <v-menu
                    ref="menuEventEndDate"
                    v-model="menuEventEndDate"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        v-model="formData.eventEndDate"
                        :error-messages="errorField.eventEndDate"
                        label="Event End Date (YYYY-MM-DD)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formData.eventEndDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menuEventEndDate = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuEventEndDate.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" dark v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      Note: Please double confirm on the event period as
                      <br />
                      this will be the final date to generate for CN
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <!-- Invoice No. -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="HRDC Invoice No."
                  v-model="formData.invoiceNo"
                  :error-messages="errorField.invoiceNo"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- taxPOD Invoice  -->
              <v-row class="pl-3" v-if="isRefundCases">
                <v-col class="d-flex" cols="12" sm="9" md="9">
                  <div
                    v-cloak
                    @drop.prevent="
                      addDropMultipleFile(
                        $event,
                        'autoGeneratedTaxpodInvoice',
                        'pdf.*'
                      )
                    "
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      multiple
                      label="Client Invoice"
                      v-model="formData.autoGeneratedTaxpodInvoice"
                      :error-messages="errorField.autoGeneratedTaxpodInvoice"
                      :truncate-length="50"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="12" lg="3" sm="3" md="3" class="pl-0 pr-5">
                  <v-btn
                    x-large
                    color="#063058"
                    outlined
                    @click="previewAttachment('autoGeneratedTaxpodInvoice')"
                    >Preview</v-btn
                  >
                </v-col>
              </v-row>

              <!-- CN Description -->
              <div v-if="isRefundCases">
                <v-col
                  v-for="(item, index) in totalCnRequireGenerate"
                  :key="index"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    rows="4"
                    dense
                    filled
                    auto-grow
                    :label="'CN Description ' + (index + 1)"
                    v-model="formData.cnDescription[index]"
                    :error-messages="errorField.cnDescription"
                  ></v-textarea>
                </v-col>
              </div>

              <!-- Payment Received Date -->
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="formData.paymentReceivedDate"
                      label="Payment Received Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.paymentReceivedDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                class="d-flex pt-0"
                cols="12"
                sm="12"
                md="12"
                v-if="isRefundCases"
              >
                <v-radio-group v-model="formData.byPassCnBool" column>
                  <template v-slot:label>
                    <div><b>Generate CN Options:</b></div>
                  </template>
                  <v-radio
                    label="Generate CN"
                    color="primary"
                    value="true"
                  ></v-radio>
                  <v-radio
                    v-if="editMode"
                    label="Regenerate CN due to changes of Claim Basis to Pay & Refund"
                    color="secondary"
                    value="regenerate"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-col>

          <!-- 70:30 Case -->
          <v-col
            cols="6"
            style="border-left: 1px solid #e8e8e8"
            v-if="formData.refundType == 2"
          >
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Grant ID -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="Grant ID"
                  v-model="formData.grantId"
                  :error-messages="errorField.grantId"
                  readonly
                >
                  <template v-slot:append>
                    <v-icon
                      color="primary"
                      @click="copyContent(formData.grantId)"
                      >mdi-content-copy
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>

              <!-- Invoice No. -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="Invoice No."
                  v-model="formData.invoiceNo2"
                  :error-messages="errorField.invoiceNo2"
                  disabled
                ></v-text-field>
              </v-col>

              <!-- Payment Received Date -->
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="formData.paymentReceivedDate2"
                      label="Payment Received Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.paymentReceivedDate2"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu2.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    isRefundCases() {
      if (this.formData.paymentChannel == 1) {
        return true;
      }
      return false;
    },
    totalCnRequireGenerate() {
      return this.formData.autoGeneratedTaxpodInvoice.length;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiGetClientInvoiceData: new Api(),
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    formData: {
      applicationNo: null,
      claimId: null,
      grantId: null,
      invoiceNo: null,
      invoiceNo2: null,
      paymentReceivedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      paymentReceivedDate2: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      refundType: null,
      refundTypeDesc: null,
      paymentChannel: null,
      autoGeneratedTaxpodInvoice: [],
      byPassCnBool: "true",
      eventStartDate: null,
      eventEndDate: null,
      cnDescription: [],
    },
    errorField: {
      applicationNo: null,
      claimId: null,
      grantId: null,
      invoiceNo: null,
      invoiceNo2: null,
      paymentReceivedDate: null,
      paymentReceivedDate2: null,
      autoGeneratedTaxpodInvoice: null,
      eventStartDate: null,
      eventEndDate: null,
      cnDescription: [],
    },
    loadingDialog: false,
    paymentReceivedSubmitDialog: false,
    editMode: false,
    btnDisabled: false,
    menuEventStartDate: false,
    menuEventEndDate: false,
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/payment_received/create-payment-received`,
        method: "post",
      },
      apiGetClientInvoiceData: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/payment_received/get-client-invoice-data`,
        method: "post",
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc_tpdiy.data.stageId];
    this.rolesAssignation(this.auth.roles);
    if (!this.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.paymentReceivedFromHrdc
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.claimApprovedFromHrdc
    );

    this.apiGetApplicationSingleDetail.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          const paymentReceivedStageId =
            this.hrdcData.stagePriority.paymentReceivedFromHrdc;

          const paymentReceived2StageId =
            this.hrdcData.stagePriority.paymentReceivedFromHrdc2;

          this.formData.refundType = data.refundType;
          if (this.formData.refundType == 1) {
            this.formData.refundTypeDesc = "Normal";
          } else {
            this.formData.refundTypeDesc = "70:30";
          }

          // Append payment channel.
          this.formData.paymentChannel = data.paymentChannel;

          // Append auto generated taxpod invoice if any.
          const autoGeneratedTaxpodInvoiceObj =
            data.ApplicationAttachment.filter(
              (item) =>
                item.stageId ==
                this.hrdcData.stagePriority.autoGeneratedTaxpodInvoice
            );

          if (autoGeneratedTaxpodInvoiceObj.length > 0) {
            let fileCounter = 0;
            const formData = new FormData();
            for (const taxpodInvoiceObj of autoGeneratedTaxpodInvoiceObj) {
              const parts = taxpodInvoiceObj.fileUrl.split(".");
              const extension = parts[parts.length - 1];

              // Create a Blob object from the data
              const blob = new Blob([
                new Uint8Array(taxpodInvoiceObj.dataArray),
              ]);

              this.formData.autoGeneratedTaxpodInvoice.push(
                new File([blob], taxpodInvoiceObj.fileName, {
                  type: `application/${extension}`,
                })
              );

              // Get the description from client invoice.
              formData.append(
                `autoGeneratedClientInvoice_${fileCounter}`,
                this.formData.autoGeneratedTaxpodInvoice[fileCounter]
              );
              fileCounter++;
            }

            formData.append("fileCounter", fileCounter);
            formData.append("serviceKey", this.model.serviceKey);

            this.apiGetClientInvoiceData.setParams(formData);
            this.apiGetClientInvoiceData.setCallbackCompleted((response) => {
              try {
                const { status, data } = response;
                if (status) {
                  this.formData.cnDescription = data;
                }
              } catch (err) {
                console.log(err);
              }
            });
            this.apiGetClientInvoiceData.fetch();
          }

          // Append company events period.
          if (data.Event) {
            const { Event } = data;
            this.formData.eventStartDate = moment(Event.startDate).format(
              "YYYY-MM-DD"
            );
            this.formData.eventEndDate = moment(Event.startDate).format(
              "YYYY-MM-DD"
            );
          }

          if (this.editMode) {
            let paymentReceivedDate, paymentReceivedDate2;

            let paymentReceived = data.ApplicationLog.find(
              (item) => item.stageId == paymentReceivedStageId
            );
            if (paymentReceived) {
              paymentReceivedDate = paymentReceived.startDate;

              this.formData.paymentReceivedDate =
                moment(paymentReceivedDate).format("YYYY-MM-DD");
            }

            let paymentReceived2 = data.ApplicationLog.find(
              (item) => item.stageId == paymentReceived2StageId
            );
            if (paymentReceived2) {
              paymentReceivedDate2 = paymentReceived2.startDate;

              this.formData.paymentReceivedDate2 =
                moment(paymentReceivedDate2).format("YYYY-MM-DD");
            }
          }

          this.formData.claimId = data.claimId;
          this.formData.grantId = data.grantApprovalNo;
          this.formData.invoiceNo = data.manualInvoiceToHrdc;
          this.formData.invoiceNo2 = data.manualInvoiceToHrdc2;
        }

        this.hideLoadingDialog();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationSingleDetail.fetch();
  },
  methods: {
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalPaymentReceivedSubmit() {
      this.paymentReceivedSubmitDialog = !this.paymentReceivedSubmitDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcTpdiyApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tpdiy.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    addDropFile(e, key, matchExtType) {
      let file = e.dataTransfer.files[0];

      if (matchExtType) {
        if (!file.type.match(matchExtType)) {
          switch (matchExtType) {
            case "pdf.*":
              this.errorField[key] = "Please select pdf file format";
              break;
            case "image/*":
              this.errorField[key] = "Please select only image";
              break;
          }
          return;
        }
      }

      this.formData[key] = file;

      if (e) {
        this.clearErrorMsg(key);
      }
    },
    addDropMultipleFile(e, key, extType) {
      for (const file of e.dataTransfer.files) {
        if (!file.type.match(extType)) {
          this.errorField[key] = "Please select correct file format";
          return;
        }

        this.formData[key].push(file);
      }
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    previewAttachment(fileType) {
      if (this.formData[fileType].length == 0) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      for (const file of this.formData[fileType]) {
        switch (fileType) {
          default:
            url = URL.createObjectURL(file);
            newWindow = window.open(url, "_blank");
            break;
        }

        if (newWindow) {
          newWindow.onload = function () {
            // Revoke the object URL when the window is closed
            newWindow.addEventListener("beforeunload", function () {
              URL.revokeObjectURL(url);
            });
          };
        }
      }
    },
    async submit() {
      this.showLoadingDialog();
      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("paymentReceivedDate", this.formData.paymentReceivedDate);
      fd.append("paymentReceivedDate2", this.formData.paymentReceivedDate2);
      fd.append("refundType", this.formData.refundType);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("paymentChannel", this.formData.paymentChannel);
      fd.append("byPassCnBool", this.formData.byPassCnBool);

      let fileCounter = 0;
      if (this.formData.autoGeneratedTaxpodInvoice) {
        for (const file of this.formData.autoGeneratedTaxpodInvoice) {
          fd.append(`autoGeneratedClientInvoice_${fileCounter}`, file);
          fd.append(
            `cnDescription_${fileCounter}`,
            this.formData.cnDescription[fileCounter]
          );
          fileCounter++;
        }
      }

      fd.append("fileCounter", fileCounter);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message:
                response.message ?? "Please fill-in all the required fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcTpdiyApplication",
              query: {
                viewId: this.$store.state.hrdc_tpdiy.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
